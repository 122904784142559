import React, { useState, useEffect } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import './styles.css';

const Dropdown = ({ list, current, onChooseItem, title, isLight = false }) => {
    const [labelItem, setLabelItem] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (list.length) {
            const { label } = list[current];
            if (typeof label !== 'undefined') {
                setLabelItem(label);
            } else {
                setLabelItem(list[current]);
            }
        }
    }, [list, current]);

    const showDropdown = () => {
        setIsOpen(!isOpen);
    };

    const chooseItem = value => () => {
        if (labelItem !== value) {
            setIsOpen(false);
            setLabelItem(value);
            onChooseItem(value);
        }
    };

    return (
        <div className={`dropdown-container ${isLight ? 'is-light' : ''}`}>
            {title && <div className="dropdown-title">{title}</div>}
            <div className={`dropdown ${isOpen ? 'open' : ''}`}>
                <button className="dropdown-button" onClick={showDropdown}>
                    {labelItem}
                    <span className="caret">
                        <MdArrowDropDown size={20} />
                    </span>
                </button>
                <ul className="dropdown-menu">
                    {list.map(value => {
                        return (
                            <li key={value}>
                                <button
                                    onClick={chooseItem(value)}
                                    value={value}
                                >
                                    {value}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Dropdown;
