import React from 'react';
import styled from 'styled-components';
import { FaExclamationCircle } from 'react-icons/fa';
const Container = styled.div`
    font-family: 'Renogare-Regular';
    margin-bottom: 24px;
    &:hover {
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 12px;
    }
`;
const Title = styled.div`
    color: ${props =>
        props.theme === 'dark'
            ? 'rgba(255, 255, 255, 0.9)'
            : 'rgba(25,23,26,0.9)'};
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    text-align: left;
    letter-spacing: normal;
    user-select: none;
    @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const Input = styled.input`
    border-radius: 2px;
    margin-top: 6px;
    outline: none;
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    transition: all 200ms ease-in-out;
    width: calc(100% - 24px);
    color: ${props =>
        props.status === 'error'
            ? '#E63939'
            : props.theme === 'dark'
            ? 'rgba(255, 255, 255, 1) '
            : 'rgba(25,23,26,0.9)'};
    padding: 12px;
    background-color: ${props =>
        props.theme === 'dark' ? '#322f33' : 'white'};
    border: ${props =>
        props.status === 'error'
            ? '1px solid #E63939'
            : props.status === 'success'
            ? '1px solid #40992E'
            : props.theme === 'dark'
            ? '1px solid black'
            : '1px solid #C7C7C7'};
    &:hover {
        cursor: text;
        border: ${props =>
            props.status === 'disable'
                ? '1px solid black'
                : '1px solid #ccaa66'};
    }
    &:focus {
        border: ${props =>
            props.status === 'disable'
                ? '1px solid black'
                : '1px solid #ccaa66'};
    }
    @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        padding: 9px;
    }
`;
const Validation = styled.div`
    background: ${props => (props.theme === 'dark' ? '#fafafa' : '#f3cccc')};
    color: #cc2929;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    font-family: 'Neuzeit Grotesk';
    border-radius: 2px;
    padding: 12px;
    display: flex;
    margin-left: 2px;
`;
const TextInput = (
    {
        title,
        value,
        onTextChange = null,
        placeholder,
        status = '',
        theme = 'dark',
        name = null,
        defaultValue = null,
        validation = null,
        register = null,
    },
    ref
) => {
    return (
        <Container>
            <Title theme={theme}>{title}</Title>
            <Input
                value={value}
                status={status}
                placeholder={placeholder}
                theme={theme}
                {...(onTextChange && {
                    onChange: e => onTextChange(e.target.value),
                })}
                {...(name && { name })}
                {...(register && { ref: register(validation) })}
            ></Input>
            <div
                style={{
                    marginTop: 15,
                    display: status === 'error' ? 'block' : 'none',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <div
                            style={{
                                width: 10,
                                height: 10,
                                position: 'absolute',
                                marginLeft: '50%',
                                background:
                                    theme === 'dark' ? '#fafafa' : '#f3cccc',
                                transform: 'rotate(45deg)',
                                marginTop: -4,
                            }}
                        ></div>
                    </div>
                </div>
                <Validation theme={theme}>
                    <FaExclamationCircle size={24} color="#E63939" />
                    &nbsp;&nbsp;{`Please input valid ${title}.`}
                </Validation>
            </div>
        </Container>
    );
};

export default TextInput;
